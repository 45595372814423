import React, { useState, useRef, useEffect } from "react";
import { Grid, CircularProgress } from "@mui/material";
import Classes from './inbox.module.css';
import ChatBox from "./chatBox";
import Options from "./Options";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import backend from "../../../api";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { setResponseText } from "../../../redux-store/auth/actions";
import SidePrompts from "./Prompts/Prompts";
import { setEditorText } from "../../../redux-store/auth/actions";
import ChatHistory from "./ChatHistory/ChatHistory";
import UpgradePopup from "../../../Global-Components/UpgradePopup/UpgradePopup";
import MobileVersionPopup from "../../../Global-Components/MobileVersionPopup/MobileVersionPopup";


const customStyles = {
  content: {
    width: "fit-content",
    height: "fit-content",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
    padding: "21px",
    backgroundColor: "rgb(237, 231, 246)"
  },
};
const fileModal = {
  content: {
    width: "300px",
    position: "absolute",
    height: "100vh",
    top: "0",
    inset: "unset",
    right: "0 !important",
    borderRadius: "5px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
    paddingLeft: "3rem",
  },
};

const styles = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '300px',
    margin: '0 auto',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    fontSize: '16px',
    fontFamily: 'Arial, sans-serif',
  },
  textarea: {
    width: '100%',
    height: '250px',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    fontSize: '16px',
    fontFamily: 'Arial, sans-serif',
    resize: 'none',
  },
  button: {
    backgroundColor: 'rgb(9 70 122)',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
};

const EditorTextstyles = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '14px',
  lineHeight: '1.5',
  color: 'black',
  whiteSpace: 'pre-wrap', // allow wrapping of long lines
  wordWrap: 'break-word' // break long words
};

function Inbox() {

  const editorRef = useRef(null);

  const editorText = useSelector((state) => state.editor.editorText);

  const { user } = useSelector((state) => state.auth);
  console.log(user);
  const [disablePage,setdisablePage] = useState(()=>{
    if(user.subscription === 'free'){
      return true
    }else{
      return false
    }
  })

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalFile, setIsModalFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileText, setFileText] = useState("");
  const [userID, setUserID] = useState(user._id);

  const [userProjects, setUserProjects] = useState([]);
  const [fileStatus, setFileStatus] = useState("");
  const [project, setProject] = useState({});
  const [selectedText, setSelectedText] = useState('');
  const [chatHistory, setChatHistory] = useState();

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = React.useState(false)

  const projectId = localStorage.getItem("projectID");
  const [showPrompts, setShowPrompts] = React.useState(false)
  const [showChatHistory, setShowChatHistory] = React.useState(true)
  const [showEditor, setShowEditor] = React.useState(true)

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setShowContextMenu(true);
    setContextMenuPosition({ x: event.clientX, y: event.clientY });
  };

  const [openUpgradePopup, setopenUpgradePopup] = useState(()=>{
    if(user?.subscription === 'monthly' || user?.subscription === 'yearly' ){
      return false;
    }else{
      return true;
    }
  });
  const closeUpgradePopup = () => {
    setopenUpgradePopup(false);
  }

  useEffect(() => {
    const getChats = async () => {
      const UserId = user._id;
      // setLoadingChat(true)
      try {
        const response = await backend.get("/chats");
        // Filter the response data to include only the required objects
        const filteredChats = response?.data?.data?.filter(
          (item) => item.UserId === UserId && item.chat.length > 0
        );
        // setLoading(false);
        // console.log(filteredChats);
        setChatHistory(filteredChats);
        console.log(response);
        // setLoadingChat(false)
      } catch (error) {
        console.error(error);
        // toast.error("Error saving story");
      }
    };
    getChats();
  }, []);

  const handleMenuItemClick = (action) => {
    // Perform the action based on the selected menu item

    switch (action) {
      case 'short':
        // Perform the "short" action
        const shorten = `Please short this: ${selectedText}`
        dispatch(setResponseText(shorten));

        break;
      case 'extend':
        // Perform the "extend" action
        const extending = `Please extend this: ${selectedText}`
        dispatch(setResponseText(extending));

        console.log('extend');
        break;
      case 'rephrase':
        // Perform the "rephrase" action
        const rephrasing = `Please rephrase this: ${selectedText}`
        dispatch(setResponseText(rephrasing));

        console.log('rephrase');
        break;
      default:
        break;
    }

    setShowContextMenu(false);
  };

  useEffect(() => {
    const handleMouseUp = () => {
      const selectedText = window.getSelection().toString();
      setSelectedText(selectedText);

      if (selectedText.length > 0) {
        // Show the context menu when text is selected
        setShowContextMenu(true);
      } else {
        // Hide the context menu when no text is selected
        setShowContextMenu(false);
      }
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleChange = (event) => {
    const eventData = event.target.value;
    console.log(project);
  };

  useEffect(() => {
    const fetchUserProjects = async () => {
      try {
        const res = await backend.get(`/project/userproject?userId=${userID}`);
        // console.log(res);
        setUserProjects(res?.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchUserProjects();
  }, [userID]);

  useEffect(() => {

    setShowEditor(true)
    if (editorText) {
      // Only insert content when editorText is not empty
      //   editorRef.current.initialValue = ""
      const htmlContent = `<pre style="font-family: ${EditorTextstyles.fontFamily}; font-size: ${EditorTextstyles.fontSize}; line-height: ${EditorTextstyles.lineHeight}; color: ${EditorTextstyles.color}; white-space: ${EditorTextstyles.whiteSpace}; word-wrap: ${EditorTextstyles.wordWrap};">${editorText}</pre>`;

      editorRef.current?.insertContent(htmlContent);
      dispatch(setEditorText(null));
    }
  }, [editorText]);

  const email = user.email;

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <CircularProgress />
      {/* <img src="/loading-2.gif" alt="gif"/> */}
    </div>) : (
    <>
      {/* <Grid container style={{ height: '100vh', overflow: 'hidden', }}>
      <Grid item xs={12} sm={12} md={8} xl={9}
        style={{ display: "flex", justifyContent: width >= 1400 ? "space-between" : "" }}
      >
        <ChatBox />
      </Grid>
      <Grid item xs={12} sm={12} xl={3} md={4} style={{
        borderLeft: "1px solid lightgray",
        overflowY: "auto",
        maxHeight: '100vh',

      }}>
        {showChatHistory ? <ChatHistory /> : null}
      </Grid>
    </Grid> */}
      <MobileVersionPopup />
      <div className={Classes.mainGrid} >
        { user.subscription === 'free' && openUpgradePopup && <UpgradePopup closeUpgradePopup={closeUpgradePopup} closeable={true} />}
        <div className={Classes.gridLeft} >
          <ChatBox setopenUpgradePopup={setopenUpgradePopup} closeUpgradePopup={closeUpgradePopup} />
        </div>
        <div className={Classes.gridRight} >
          {showChatHistory ? <ChatHistory /> : null}
        </div>
      </div>
    </>


  )
}


export default Inbox;